import React, { useContext } from "react";
import { QuestionSection, RadioRange } from "../../components/utility/quote";
import { navigate } from "gatsby";

import CopierContext from "../../contexts/copierContext";
import RadioCircle from "../../svgs/radioCircle";

const Next = "/copier-printer/Questions/4";
const Points = [
  {
    question: "Do you wish to purchase or lease?",
    progress: 70,
    options: [
      {
        answer: "Purchase (Price start from $2000)",
        to: Next,
        icon: <RadioCircle />,
      },
      {
        answer: "Lease (Start from $80/month)",
        to: Next,
        icon: <RadioCircle />,
      },
      {
        answer: "Compare lease & Purchase prices",
        to: Next,
        icon: <RadioCircle />,
      },
    ],
  },
];

export default function Question3({ title }) {
  const { que3, setQue3 } = useContext(CopierContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <RadioRange
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que3 === items.answer}
              onChange={(e) => {
                setQue3(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
