import { createContext } from "react";
const initialstate = {
  que1: "",
  que2: "",
  que3: "",
  que4: "",
};
const CopierContext = createContext(initialstate);
CopierContext.displayName = "CopierContext";

export default CopierContext;
